@import './typography';
@import './breakpoints';

:root {
  //font-weights
  --small-weight: 300;
  --regular-weight: 400;
  --big-weight: 500;

  //fonts
  --space-grotesk: 'Space Grotesk', -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  --program: program, sans-serif;
  --copperplate: copperplate, serif;
  --arkhip: arkhip, sans-serif;

  //translucent colours
  --translucent-white-60: hsla(0, 0%, 100%, 0.6);
  --translucent-black-60: hsla(0, 0%, 0%, 0.6);
  --translucent-teal-60: hsla(178, 29%, 53%, 0.6);

  //colours
  --ink-lightest: hsla(220, 7%, 75%, 1);
  --ink-lighter: hsla(220, 2%, 48%, 1);
  --ink-light: hsla(220, 6%, 30%, 1);
  --ink-medium-light: hsla(222, 15%, 17%, 1);
  --ink-base: hsla(220, 16%, 13%, 1);
  --ink-dark: hsla(220, 15%, 8%, 1);
  --ink-darkest: hsla(220, 14%, 1%, 1);
  --sky-white: hsla(0, 0%, 100%, 1);
  --sky-lightest: hsla(220, 10%, 96%, 1);
  --sky-lighter: hsla(220, 6%, 93%, 1);
  --sky-light: hsla(220, 3%, 89%, 1);
  --sky-base: hsla(220, 2%, 84%, 1);
  --sky-dark: hsla(220, 1%, 76%, 1);
  --gold-lightest: hsla(36, 100%, 92%, 1);
  --gold-lighter: hsla(36, 98%, 68%, 1);
  --gold-light: hsla(36, 82%, 51%, 1);
  --gold-base: hsla(36, 88%, 43%, 1);
  --gold-darkest: hsla(36, 89%, 37%, 1);
  --red-lightest: hsla(0, 100%, 95%, 1);
  --red-light: hsla(0, 100%, 71%, 1);
  --red-darkest: hsla(4, 89%, 44%, 1);
  --green-lightest: hsla(147, 90%, 92%, 1);
  --green-darkest: hsla(147, 64%, 29%, 1);
  --teal-lightest: hsla(176, 100%, 93%, 1);
  --teal-lighter: hsla(176, 98%, 83%, 1);
  --teal-light: hsla(177, 61%, 66%, 1);
  --teal-base: hsla(185, 10%, 44%, 1);
  --teal-darkest: hsla(222, 26%, 23%, 1);

  //box-shadows
  --box-shadow-small: 0px 0px 8px rgba(20, 20, 20, 0.08);
  --box-shadow-medium: 0px 1px 8px 2px rgba(20, 20, 20, 0.08);
  --box-shadow-large: 0px 1px 24px 8px rgba(20, 20, 20, 0.08);

  //border-radius
  --border-radius-xs: 8px;
  --border-radius-small: 12px;
  --border-radius-regular: 16px;
  --border-radius-large: 18px;
  --border-radius-xlarge: 32px;

  // footer & nav heights
  --footer-height: 88px;
  --navbar-height: 84px;
  --body-height: calc(100vh - (var(--footer-height) + var(--navbar-height)));

  //transitions

  --fade-transition: opacity 0.3s ease-in-out;

  @media (max-width: $tablet) {
    --navbar-height: 76px;
    --body-height: calc(100vh - var(--navbar-height));
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--space-grotesk);
  color: var(--sky-white);
}

body {
  background-color: var(--ink-darkest);
  min-height: 100vh;
  min-height: -webkit-fill-available;
  scrollbar-color: var(--ink-lighter) var(--ink-light);
  scrollbar-width: thin;
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: var(--ink-light);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--ink-lighter);
    border-radius: 20px;
  }
}

html {
  height: -webkit-fill-available;
}

* {
  box-sizing: border-box;
}

.hideLoader {
  display: none;
}

button {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;

  &:visited {
    color: inherit;
  }
}

dd {
  margin: 0;
}

input {
  font-size: 1rem;
  font-family: var(--space-grotesk);
  font-weight: var(--small-weight);
}

input,
textarea {
  @include body-regular-book;
  color: var(--sky-white);
  background-color: var(--ink-medium-light);
  border: 1px solid var(--ink-medium-light);
  border-radius: 4px;
  height: 36px;
  width: 100%;
  padding-left: 10px;
}

textarea {
  width: 100%;
  height: 100%;
  background-color: transparent;
  font-size: 1rem;
  resize: none;
  padding-top: 8px;
}

input:placeholder-shown + label {
  color: transparent;
}

textarea:placeholder-shown + label {
  color: transparent;
}

textarea::placeholder {
  color: var(--ink-lightest);
  @include body-regular-book;
}

label {
  font-size: 12px;
}

.link {
  text-decoration: underline;
  text-decoration-color: var(--ink-lighter);
}

.jump {
  position: relative;
  padding-top: 16px;
}

.jump > input {
  display: block;
  width: 100%;
  padding: 2px 8px;
}

.jump input::-webkit-input-placeholder {
  color: var(--ink-lightest);
  @include body-regular-book;
}

.jump > input:focus {
  outline: 1px solid white;
  color: white;
}

.jump > textarea {
  display: block;
  width: 100%;
}

.jump > textarea:focus {
  outline: 1px solid white;
  color: white;
}

.jump > label {
  position: absolute;
  left: 0;
  top: 0;
  color: white;
  transition: all 0.3s ease-in-out;
  cursor: text;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--ink-medium-light) inset !important;
  -webkit-text-fill-color: var(--sky-white) !important;
  background-clip: content-box !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

input[type='number'] {
  @include sub-heading-1;
  text-align: center;
  padding: 24px 0 23px 0;
  margin-top: 11px;
  background-color: var(--ink-base);
  border-color: var(--ink-base);

  &:focus {
    outline: 1px solid white;
  }
}

.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-top: 40px;

  @media (max-width: $sm) {
    margin-top: 24px;
    gap: 8px;
  }

  @media (max-width: $xs) {
    gap: 4px;
  }
}

.home-page {
  display: grid;
  place-content: center;
  height: calc(100vh - 120px);

  & > h1 {
    text-align: center;
  }
}

#modal-full {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px) saturate(80%);
  overflow: scroll;
  display: block;
  pointer-events: all;
  animation-name: backgroundfadein;
  animation-duration: 0.5s;
}

#creature-modal-box {
  z-index: 2;
  position: relative;
  display: block;
  background-color: black;
  color: white;
  margin: 0 auto;
  padding: 0px;
  top: 50%;
  transform: translateY(-60%);
  width: 25%;
  max-width: 500px;
  min-width: 310px;
  box-shadow: 0 5px 8px 0 rgb(0 0 0 / 80%), 0 7px 20px 0 rgb(0 0 0 / 80%);
  pointer-events: all;
  background-color: rgba(0, 0, 0, 0.5);
  // backdrop-filter: blur(10px) saturate(80%);
  border-radius: 0px;
  animation-name: popmodal;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  max-height: 85%;
}

#creature-image {
  max-width: 500px;
  width: 100%;
  style: inline;
  alignment: centre;
}

#modal-head {
  font-color: white;
  // font-family: Copperplate;
  font-size: 1.5em;
  background-color: #131313;
  display: block;
  padding: 8px;
  text-align: center;
  margin: 0px;
}

#modal-body-text {
  padding: 10px 25px 30px;
  // font-family: SpaceGrotesk;
  background-color: #131313;
  font-size: 16px;
}

#modal-foot {
  padding-top: 11px;
  padding-bottom: 11px;
  // font-family: SpaceGrotesk;
  font-size: 10px;
  background-color: #131313;
  display: block;
  text-align: center;
  margin-top: 0px;
  border-top: 5px solid black;
  margin-bottom: 0px;
}

@keyframes popmodal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes shrinkmodal {
  100% {
    opacity: 0;
  }
  0% {
    opacity: 1;
  }
}

@keyframes backgroundfadeout {
  100% {
    opacity: 0;
  }
  0% {
    opacity: 1;
  }
}

@keyframes backgroundfadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// FIXME: Remove once Wallet Connect 2 actually works
.css-34pixr-MuiPaper-root-MuiCard-root {
  button:nth-of-type(2) {
    display: none;
  }
}
